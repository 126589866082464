import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";


const SEO = ({ title, description }) => {

  const data = useStaticQuery(graphql`
  {
    site {
      siteMetadata {
        author
        siteDescription: description
        image
        siteTitle: title
      }
    }
  }`)

  const {siteDescription, siteTitle, image} = data.site.siteMetadata;

  return (
    <></>
  // <Helmet htmlAttributes={{lang: "en"}} title={`${title} | ${siteTitle}`}>
  //   <meta name="description" content={description || siteDescription}></meta>
  //   <meta name="image" content={image}></meta>
  // </Helmet>
  )
}

export default SEO;
