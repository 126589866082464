import React, { useState } from "react";
import { graphql } from "gatsby";
import { Col, Container, Form, FormControl, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import LoadingOverlay from 'react-loading-overlay';
import Recaptcha from 'react-google-recaptcha';

import Layout from "../components/Layout";
import SEO from "../components/seo";
import { AddContactRecord, cmsCollections } from "../services/cmsService";
import { getToken } from "../services/auth";
import { global, loaderStyle, waitingText } from '../constants/common';
import ToastContainer, { EMessageModeEnum } from "../components/shared/Toast/ToastContainer";

const Contact = ({data}) => {

    const RECAPTCHA_KEY = process.env.GATSBY_APP_SITE_RECAPTCHA_KEY;

    const [currentContactId, setContactId] = useState(undefined);
    const { register, handleSubmit } = useForm();

    const [ isLoading, setIsLoading ] = useState(false);
    const [message, setMessage] = useState('');
    const [ messageMode, setMessageMode ] = useState(EMessageModeEnum.SUCCESS);
    
    // // for recaptcha
    const recaptchaRef = React.createRef()

    const { contactTitle, contactDescription } = data?.seo?.nodes[0];

    const token = getToken();

    const onSubmit = async (data) => {
        const recaptchaValue = recaptchaRef?.current?.getValue();

        if (!recaptchaValue) {
            showMessage("You need complete the captcha ", EMessageModeEnum.ERROR);
        } else {
            console.log("jm: recaptchaValue ", recaptchaValue);
            const payload = {
                ...data,
                gRecaptchaResponse: recaptchaValue,
            }
    
            setIsLoading(true);
            const result  = await AddContactRecord('', cmsCollections.ContactEnquiries, payload);
            setIsLoading(false);
    
            showMessage("Contact Enquiry sent !", EMessageModeEnum.SUCCESS);
            
            // set the contactId
            setContactId(result.data.id);
        }
    }

    let timeout: any;
    const showMessage = (message: string, mode: EMessageModeEnum) => {
        setMessage(message);
        setMessageMode(mode);
    };

  return (
    <Layout>
        <SEO title={contactTitle} description={contactDescription}></SEO>
        <Container>
            <LoadingOverlay active={isLoading} spinner text={waitingText} styles={loaderStyle}>
            <ToastContainer message={message} mode={messageMode} />
            <Row>
                <Col>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <br></br>
                        <h3>Contact Us</h3>
                        <Row>
                            <Col sm={9}>
                                <Row >
                                    <Col sm={4}>
                                        <Form.Group controlId="formFirstNameFirstName">
                                            <Form.Label>First Name</Form.Label>
                                            <FormControl type="text" name="firstname" placeholder="Enter Firstname" {...register('firstname')} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group controlId="formFirstNameLastName">
                                            <Form.Label>Last Name</Form.Label>
                                            <FormControl type="text" name="lastname" placeholder="Enter Lastname" {...register('lastname')} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col sm={8}>
                                        <Form.Group controlId="formEmail">
                                            <Form.Label>Email address</Form.Label>
                                            <FormControl type="email" name="email" placeholder="Enter your email address" {...register('email')} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col sm={8}>
                                        <Form.Group controlId="formMobile">
                                            <Form.Label>Mobile Number</Form.Label>
                                            <FormControl type="number" name="mobilenumber" placeholder="Enter your mobile number" {...register('mobilenumber')} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col sm={8}>
                                    <Form.Group controlId="formUserResearchOrgAdditionalInfo">
                                        <Form.Label>Comment/Enquiry</Form.Label>
                                        <Form.Control as="textarea" rows={3} placeholder="Enter your comment/enquiry here" {...register('enquiry')} />
                                    </Form.Group>
                                    </Col>
                                </Row>
                                <br></br>
                                <Recaptcha ref={recaptchaRef} sitekey={RECAPTCHA_KEY} />
                                <br></br>
                                    <button className="btn btn-primary" type="submit">Submit</button>   
                                <br></br>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            </LoadingOverlay>
            <br></br>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    seo: allStrapiSeo {
        nodes {
          contactDescription
          contactTitle
        }
      },
  }
`

export default Contact;
